








































import { Component, Vue } from "vue-property-decorator";
import Banner from "./Banner.vue";
import firebase from "@/plugins/firebase";

@Component({
  components: {
    Banner
  },
  metaInfo: {
    title: "KAHEV News"
  }
})
export default class BlogIndex extends Vue {
  news = [] as News[];

  async getNews() {
    try {
      const newsQuery = await firebase
        .firestore()
        .collection("/apps/kahev-org/news-en")
        .where("disabled", "==", false)
        .orderBy("dateCreated", "desc")
        .get();

      newsQuery.docs.forEach(docRef => {
        this.news.push({
          id: docRef.id,
          urlCode: docRef.data().urlCode,
          title: docRef.data().title,
          text: docRef.data().text,
          previewImg: docRef.data().previewImg,
          dateCreated: docRef.data().dateCreated
        } as News);
      });
    } catch (err) {
      console.error(err);
    }
  }

  mounted() {
    this.getNews();
  }
}
